/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { ContentPageLayout } from '@components/Layout';
import { graphql } from 'gatsby';
import { IMarkdownData } from '../../types';


interface IPrivacyPolicyPage {
  data: IMarkdownData;
}

const PrivacyPolicyPage: FC<IPrivacyPolicyPage> = ({
  data: { markdownRemark: { frontmatter: { blocks: texts, featuredImage }, htmlAst } },
}) => {
  return (
    <ContentPageLayout featuredImage={featuredImage} htmlContent={htmlAst} />
  );
};


export const pageQuery = graphql`
query PrivacyPolicyQuery {
  markdownRemark(frontmatter: {path: {eq: "/privacy-policy/"}}) {
    frontmatter {
      path
      featuredImage {
        childImageSharp {
          fluid(maxWidth: 962) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    htmlAst
  }
}`;
export default PrivacyPolicyPage;
